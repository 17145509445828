import React, { useEffect, useRef, useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Label,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import CountUp from "react-countup";
// core components
import { useLocation, useNavigate } from "react-router-dom";
// import { getCampaignDetails } from "action/campaign/createCampaign";
import { useDispatch, useSelector } from "react-redux";
import NotificationAlert from "react-notification-alert";
// import CampaignAccordian from "./CampaignAccordian";
// import InviteSendingProgress from "./InviteSendingProgress";
// import {
//   clearAttendeeListFrmStore,
//   clearCampaignDetailsFrmStore,
// } from "action/campaign/createCampaign";

import CampaignAccordian from "./CampaignAccordian";
import TimelineHeader from "components/Headers/SimpleHeader";
import { cyanBlue } from "../constants/Constants";
import { getCampaignDetails } from "action/plans";
import { getAttendeeList } from "action/plans";
import CountsCard from "../common/CountsCard";

function SaCampaignDetails() {
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const notificationAlertRef = React.useRef(null);
  const campaignDetails = useSelector(
    (state) => state.TenantDetailsReducer.campaignDetails
  );
  const attendeeList = useSelector(
    (state) => state.TenantDetailsReducer.attendeeList
  );
  const [cardFilter, setCardFilter] = useState("");
  const location = useLocation();
  const id = location?.state?.id;
  const campaignCode = location.state?.campaignCode;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  useEffect(() => {
    getCampaignDetails(dispatch, campaignCode, id);
    getAttendeeList(dispatch, campaignCode, id);
  }, [dispatch, id, campaignCode]);

  const handleCardClick = (filterType) => {
    setCardFilter(filterType);
    tableRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const notify = (type, msgObj) => {
    if (isNotificationOpen && !msgObj.showForceFully) return;
    setIsNotificationOpen(true); // Set the notification as open
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {msgObj.message}
          </span>
          <span data-notify="message">{msgObj.subMessage}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000);
  };

  return (
    <>
      <TimelineHeader name="Tenants" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {/* <div className="header bg-info pt-6"></div> */}
      <Container className="mt--6" fluid>
        {!campaignDetails.ID && !campaignDetails.message ? (
          <Card>
            <div style={styles.loaderContainer}>
              <i
                className="fa fa-spinner fa-spin fa-2xl"
                style={styles.loaderColor}
              />
            </div>
          </Card>
        ) : campaignDetails.message ? (
          <Card>
            <CardHeader>
              <i
                className="fa fa-arrow-left mr-2 "
                style={styles.cursor}
                onClick={() => {
                  navigate(`/admin/tenants/details`, {
                    state: { id: id, tabValue: "CAMPAIGN_LISTING" },
                  });
                }}
              />{" "}
            </CardHeader>
            <div style={styles.loaderContainer}>
              <span className="text-red">{campaignDetails?.code}</span>
              {campaignDetails.message}
            </div>
          </Card>
        ) : (
          <>
            <Card className="mb-4">
              <CardHeader>
                <Row md={12} lg={12} className="d-flex justify-content-between">
                  <Col xs={8} className="d-flex align-items-center">
                    <h3 className="mb-0">
                      <i
                        className="fa fa-arrow-left mr-2 "
                        style={styles.cursor}
                        onClick={() => {
                          navigate(`/admin/tenants/details`, {
                            state: { id: id, tabValue: "CAMPAIGN_LISTING" },
                          });
                        }}
                      />{" "}
                      Campaign: {campaignDetails?.campaignName}
                    </h3>
                  </Col>
                  <Col
                    xs={4}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <Button
                      type="button"
                      outline
                      color="info"
                      onClick={() =>
                        navigate("/admin/tenants/campaign/logs", {
                          state: { id: id, campaignCode: campaignCode },
                        })
                      }
                    >
                      Show Logs
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Col md={12} className="mb-2">
                  <Row className="align-items-center">
                    <Label>Campaign Status - </Label>
                    <Label
                      className="ml-2"
                      style={{
                        fontWeight: "500",
                        color:
                          campaignDetails?.status === "FAILED"
                            ? "red"
                            : campaignDetails?.status === "IN-PROGRESS"
                            ? "orange"
                            : campaignDetails?.status === "COMPLETED"
                            ? "green"
                            : campaignDetails?.status === "RETRY"
                            ? "Brown"
                            : campaignDetails?.status === "MODIFIED"
                            ? "purple"
                            : "SteelBlue", //for PENDING
                      }}
                    >
                      {campaignDetails?.status}
                    </Label>
                    {campaignDetails?.status === "FAILED" && (
                      <small className="text-red ml-2">
                        (We will retry sending invites.)
                      </small>
                    )}
                  </Row>
                </Col>

                <Row md={12}>
                  <Col md={4}>
                    <Label>Start Date</Label>
                    <p className="button">{campaignDetails?.startAt}</p>
                  </Col>
                  <Col md={4}>
                    <Label>End Date</Label>
                    <p className="button">{campaignDetails?.endAt}</p>
                  </Col>
                  <Col md={4}>
                    <Label>Time Zone</Label>
                    <p className="button">
                      {campaignDetails?.timeZone?.location}
                    </p>
                  </Col>
                </Row>
                <Row md={12} className="mt-4">
                  <Col md={4}>
                    <Label>Organizer Name</Label>
                    <p className="button">{campaignDetails?.organizerName}</p>
                  </Col>
                  <Col md={4}>
                    <Label>Organizer Email</Label>
                    <p className="button">{campaignDetails?.organizerEmail}</p>
                  </Col>
                  <Col md={4}>
                    <Label>Email Configuration</Label>
                    <p className="button">
                      {campaignDetails?.emailConfiguration?.title}
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <CountsCard
              tableRef={tableRef}
              onCardClick={handleCardClick}
              setCardFilter={setCardFilter}
              showStatus={false}
              campaignDetails={campaignDetails}
            />
            <div ref={tableRef}>
              <CampaignAccordian
                campaignDetails={campaignDetails}
                attendeeList={attendeeList}
                tenantID={id}
                campaignCode={campaignCode}
                notify={notify}
                dispatch={dispatch}
                cardFilter={cardFilter}
                handleCardClick={handleCardClick}
              />
            </div>
          </>
        )}
      </Container>
    </>
  );
}

export default SaCampaignDetails;

const styles = {
  cursor: { cursor: "pointer" },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "70vh",
    flexDirection: "column",
  },
  loaderColor: { color: cyanBlue },
};
