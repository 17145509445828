import { combineReducers } from "redux";
import planReducer from "./PlansReducers/plans";
import TenantReducer from "./TenantsReducers/tenant";
import CountryReducer from "./DashboardReducers/countrys";
import TenantDetailsReducer from "./DashboardReducers/details";
import RedirectLinksReducer from "./RedirectLinksReducer/RedirectLinks";

export const reducers = combineReducers({
  planReducer,
  TenantReducer,
  CountryReducer,
  TenantDetailsReducer,
  RedirectLinksReducer,
});
