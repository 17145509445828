/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getPlanWiseUsers } from "action/plans";

function CardsHeader({ name }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const plans = useSelector((state) => state.planReducer.plansTenantList);

  React.useEffect(() => {
    getPlanWiseUsers(dispatch);
  }, [dispatch]);

  // const handleNavigate = (id) => {
  //   navigate("/admin/plans", { state: { ID: id - 1 } });
  // };

  const handleIcons = (planName) => {
    switch (planName) {
      case "Free Plan":
        return (
          <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow ">
            <i className="fa-solid fa-location-arrow" />
          </div>
        );
      case "Starter":
        return (
          <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
            <i className="fa-solid fa-lightbulb" />
          </div>
        );
      case "Elite":
        return (
          <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow">
            <i className="fa-solid fa-gem" />
          </div>
        );
      case "Premium":
        return (
          <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
            <i className="fa-solid fa-crown" />
          </div>
        );
      case "Pro":
        return (
          <div className="icon icon-shape bg-gradient-danger text-white rounded-circle shadow">
            <i className="fa-solid fa-trophy" />
          </div>
        );
    }
  };

  return (
    <>
      <div className="header bg-info pb-6 mt--3">
        <Container fluid>
          <div className="header-body">
            {/* <Row>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Available Credits
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          350,897
                        </span>
                      </div>
                      <Col className="col-auto mt-2">
                        <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                          <i className="ni ni-active-40" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Total Campaign
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">2,356</span>
                      </div>
                      <Col className="col-auto mt-2">
                        <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                          <i className="ni ni-chart-pie-35" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Invite Sent in 30 days
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">924</span>
                      </div>
                      <Col className="col-auto mt-2">
                        <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                          <i className="ni ni-money-coins" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="card-stats">
                  <CardBody>
                    <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Sending-In Progress
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">49</span>
                      </div>
                      <Col className="col-auto mt-2">
                        <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                          <i className="ni ni-chart-bar-32" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
            <Row className="align-items-center py-3 ml-1">
              <Col lg="6" xs="7">
                <Row className="d-flex">
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    {/* {name} */}
                  </h6>
                </Row>
              </Col>
            </Row>
            <Row className="d-flex justify-content-evenly">
              {plans.data?.map((item, index) => (
                <Col key={item.ID}>
                  {/* <Row>
                        {item.popular === "YES" && (
                          <Badge color="white" style={styles.badge}>
                            Popular
                          </Badge>
                        )}
                      </Row> */}
                  <Row className="h-75">
                    <Card
                      className="card-stats h-100 w-100 mx-3 poin"
                      style={styles.pointer}
                      onClick={() =>
                        navigate("/admin/tenants", {
                          state: { planName: item.name },
                        })
                      }
                    >
                      <CardBody className="px-3">
                        <Row>
                          <Col>
                            <CardTitle
                              tag="h3"
                              className="text-uppercase text-muted mb-0"
                            >
                              {item.name}
                            </CardTitle>
                            <span className="h1 font-weight-bold mb-0 mr-1">
                              {item.userCount}
                            </span>
                            <span className="text-muted text-xs mb-0">
                              Users
                            </span>
                          </Col>
                          <Col className="d-flex flex-column justify-content-center align-items-end">
                            {handleIcons(item.name)}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Row>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
};

const styles = {
  pointer: { cursor: "pointer" },
};

export default CardsHeader;
