import moment from "moment";

// Function to convert Date to ISO format
export function convertToISOFormat(dateString) {
  // Parse the input date string into a Date object
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid date string");
    return;
  }

  // Extract components for the ISO format
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Format the date in 'YYYY-MM-DDTHH:mm' format
  const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

  return isoFormattedDate;
}

//a function which converts UTC to local time of user
export const convertUTCToLocal = (utcDate) => {
  const isoFormattedDate = convertToISOFormat(utcDate); //first convert in ISO format
  return moment.utc(isoFormattedDate).local().format("D MMM YYYY, hh:mm a"); // Convert UTC to local time
};
