import React, { useRef } from "react";
import classnames from "classnames";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import AuthHeader from "components/Headers/AuthHeader.js";
import { useNavigate } from "react-router-dom";
import { USER_LOGIN, REACT_APP_SITE_KEY } from "API/apiConstants";
// react plugin for creating notifications over the dashboard
import NotificationAlert from "react-notification-alert";
import ReCAPTCHA from "react-google-recaptcha";

function Login() {
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const [forgetPass, setForgetPass] = React.useState(false);
  const [formData, setFormData] = React.useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = React.useState({
    email: "",
    password: "",
  });
  const [isCaptchaSet, setIsCaptchaSet] = React.useState(false);
  const [isNotificationSet, setIsNotificationSet] = React.useState(false);
  const [showLoaderOnClick, setShowLoaderOnClick] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const token = JSON.parse(localStorage.getItem("p"))?.token;

  const notificationAlertRef = React.useRef(null);
  const notify = (type, msg) => {
    if (isNotificationSet) {
      return;
    }
    setIsNotificationSet(true);
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {msg}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current.notificationAlert(options);
    setTimeout(() => {
      setIsNotificationSet(false);
    }, 3000);
  };

  React.useEffect(() => {
    if (token) navigate("/admin/dashboard");
  }, []);

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get("error");
    if (error) {
      notify("error", error);
    }
  }, []);

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "email") {
      setErrors({ ...errors, email: value ? "" : "This field is required" });
    }
  };
  const validateForm = () => {
    let isValid = true;
    // Email Validation: Check if the email is present and properly formatted
    if (!formData.email && !forgetPass) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "This field is required",
      }));
      isValid = false;
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "Please enter a valid email address",
      }));
      isValid = false;
    } else if (!formData.password && !forgetPass) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: "This field is required",
      }));
      isValid = false;
    } else {
      // !forgetPass
      // ? navigate("/admin/dashboard")
      // : navigate("/auth/reset/password");
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
    return isValid;
  };
  const handleSubmit = () => {
    // const token = captchaRef.current.getValue();
    // captchaRef.current.reset();
    if (validateForm() && isCaptchaSet) {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      fetch(USER_LOGIN, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(formData),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.code === 200) {
            localStorage.setItem("p", JSON.stringify(res.data));
            navigate("/admin/dashboard");
          } else {
            notify("error", res.message);
            setShowLoaderOnClick(false);
          }
        });
    } else {
      if (!isCaptchaSet) {
        notify("error", "Captcha is mandatory");
        setShowLoaderOnClick(false);
      } else {
        notify("error", "Please fill correct data.");
        setShowLoaderOnClick(false);
      }
    }
  };
  const handleSubmitDisabled = () => {
    if (formData.email === "" || formData.password === "" || !isCaptchaSet) {
      return true;
    } else {
      return false;
    }
  };
  const togglePasswordState = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmitOnEnter = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <AuthHeader title="Welcome!" />
      <Container className="mt--8 pb-3">
        <Row className="justify-content-center mt-2">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              {!forgetPass && (
                <CardHeader className="bg-transparent pb-3">
                  <div className="text-muted text-lg text-center mt-2 mb-2">
                    Admin Page
                  </div>
                  <div className="btn-wrapper text-center">
                    {/* <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <span className="btn-inner--icon mr-1">
                        <img
                          alt="..."
                          src={
                            require("assets/img/icons/common/Microsoft_logo.svg")
                              .default
                          }
                        />
                      </span>
                      <span className="btn-inner--text">Microsoft</span>
                    </Button> */}
                  </div>
                </CardHeader>
              )}
              <CardBody className="px-lg-5 py-lg-5 mt--3">
                {/* <div className="text-center text-muted mb-3">
                  {!forgetPass ? (
                    <small>Sign in with credentials</small> 
                  ) : (
                    <small>Enter your mail to verify</small>
                  )}
                </div> */}
                <Form role="form" onKeyPress={handleSubmitOnEnter}>
                  <FormGroup
                    className={classnames("mb-3", { focused: focusedEmail })}
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onFocus={() => setfocusedEmail(true)}
                        onBlur={() => setfocusedEmail(false)}
                        error={errors.email}
                      />
                    </InputGroup>
                    {errors.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                  </FormGroup>

                  {!forgetPass && (
                    <FormGroup
                      className={classnames({ focused: focusedPassword })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fa fa-lock" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          name="password"
                          onChange={handleChange}
                          onFocus={() => setfocusedPassword(true)}
                          onBlur={() => setfocusedPassword(false)}
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i
                              className={`fa-solid ${
                                showPassword ? "fa-eye-slash" : "fa-eye"
                              }`}
                              onClick={togglePasswordState}
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      {errors.password && (
                        <small className="text-danger">{errors.password}</small>
                      )}
                    </FormGroup>
                  )}
                  <div className="text-center mt-3">
                    <a
                      className="text-blue"
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        setForgetPass(!forgetPass);
                        setErrors({});
                      }}
                    ></a>
                  </div>
                  <ReCAPTCHA
                    sitekey={REACT_APP_SITE_KEY}
                    ref={captchaRef}
                    onChange={() => setIsCaptchaSet(true)}
                  />
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="info"
                      type="button"
                      onClick={() => {
                        setShowLoaderOnClick(true);
                        handleSubmit();
                      }}
                      disabled={handleSubmitDisabled()}
                    >
                      {showLoaderOnClick ? (
                        <i className="fa fa-spinner fa-spin fa-lg text-white" />
                      ) : !forgetPass ? (
                        "Sign in"
                      ) : (
                        "Send Recovery Email"
                      )}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Login;
