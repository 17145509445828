/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// react library for routing
import { Link, useLocation, useNavigate } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button,
} from "reactstrap";

function AdminNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location;
  const [login, setLogin] = useState(searchParams.pathname === "/auth/login");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  React.useEffect(() => {
    if (login) {
      if (searchParams.search) {
        return;
      } else {
        navigate("/auth/login");
      }
      navigate("/auth/login");
    }
    //Done to make the text and logo responsive
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [login]);
  const styles = {
    container: {
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    },
    image: {
      width: windowWidth <= 576 ? "40px" : windowWidth <= 768 ? "50px" : "65px",
      height:
        windowWidth <= 576 ? "35px" : windowWidth <= 768 ? "45px" : "59px",
    },
    text: {
      fontFamily: "rubik",
      color: "white",
      fontSize:
        windowWidth <= 576 ? "24px" : windowWidth <= 768 ? "28px" : "36px",
      fontWeight: 500,
      textTransform: "none",
      marginLeft: "10px", // Spacing between image and text
      marginBottom: 0, // Ensure no extra margin below the text
    },
  };
  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark navbar-transparent"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand className="mt-2 w-100">
            <div style={styles.container}>
              <img
                alt="..."
                className="navbar-brand-img"
                src={require("assets/img/brand/bulk-logo.png")}
                style={styles.image}
              />
              <p style={styles.text}>BulkCalendar</p>
            </div>
            {/* <span>Bulk Calendar</span> */}
            {/* <img
              alt="..."
              src={require("assets/img/brand/argon-react-white.png")}
            /> */}
          </NavbarBrand>
          {/* <button
            aria-controls="navbar-collapse"
            aria-expanded={false}
            aria-label="Toggle navigation"
            className="navbar-toggler"
            data-target="#navbar-collapse"
            data-toggle="collapse"
            id="navbar-collapse"
            type="button"
          >
            <span className="navbar-toggler-icon" />
          </button> */}
          <UncontrolledCollapse
            className="navbar-custom-collapse"
            navbar
            toggler="#navbar-collapse"
          >
            <div className="navbar-collapse-header">
              <Row>
                <Col className="collapse-brand" xs="6">
                  <Link to="/admin/dashboard">
                    <img alt="..." src={require("assets/img/brand/blue.png")} />
                  </Link>
                </Col>
                <Col className="collapse-close" xs="6">
                  <button
                    aria-controls="navbar-collapse"
                    aria-expanded={false}
                    aria-label="Toggle navigation"
                    className="navbar-toggler"
                    data-target="#navbar-collapse"
                    data-toggle="collapse"
                    id="navbar-collapse"
                    type="button"
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <Nav className="mr-auto" navbar></Nav>
            <hr className="d-lg-none" />
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
