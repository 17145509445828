import React from "react";

import TenantTab from "./Components/TenantTab";
import TimelineHeader from "components/Headers/SimpleHeader";

function Tenants() {
  return (
    <>
      <TimelineHeader name="Tenants" />
      <TenantTab activeTab="Tenants" />
    </>
  );
}

export default Tenants;
