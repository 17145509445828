import { Backdrop } from "@mui/material";
import { getTenantPlanDetails } from "action/plans";
import { addCredits } from "action/plans";
import { convertUTCToLocal } from "pages/CommonFunctions";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Col,
  Input,
  Label,
  Row,
  Button,
  Form,
  Modal as RSModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "reactstrap";
import Swal from "sweetalert2";
function AddCredits(props) {
  const [creditsData, setCreditsData] = useState({
    tenantID: props.tenantId,
    credits: 0,
    remark: "",
  });
  const [creditsCost, setCreditsCost] = useState(0);
  const [err, setErr] = useState(false);
  const [isValid, setIsValid] = useState(true); // New state to track validity
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (creditsData.credits >= 0 && props.ratePerCredit >= 0) {
      // calculate total cost of new added credits
      let totalCosts = creditsData.credits * props.ratePerCredit;
      setCreditsCost((Math.round(totalCosts * 100) / 100).toFixed(2));
      setIsValid(true); // Set valid if conditions are met
    } else {
      setIsValid(false); // Set invalid if conditions are not met
    }
  }, [creditsData]);

  // When-ever modal is open values are set to inital state
  useEffect(() => {
    setErr(false);
    setCreditsData({ tenantID: props.tenantId, credits: 0, remark: "" });
    setCreditsCost(0);
    setIsValid(true); // Reset validity on close
  }, [props.isOpen]);

  const handleInput = (e) => {
    setErr(false);
    let { name, value } = e.target;
    if (name === "credits") {
      if (value.length <= 6 && value >= 0)
        setCreditsData({ ...creditsData, [name]: Number(value) });
    } else if (name === "remark") {
      setCreditsData({ ...creditsData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tempCredits = creditsData.credits;
    // trim remark text before passing it to api
    const trimCreditData = {
      ...creditsData,
      remark: creditsData.remark.trim(),
    };
    if (tempCredits > 0 && !err) {
      props.handleClose();
      setIsLoading(true);
      const res = await addCredits(trimCreditData);
      setIsLoading(false);
      if (res?.data?.code === 200) {
        Swal.fire({
          title: `${res.data.message}`,
          icon: "success",
          showConfirmButton: false,
          timer: 3000,
        });
        //call getTenantPlanDetails api after successfully adding new credits so that updated records will display
        getTenantPlanDetails(dispatch, props.tenantId);
      } else {
        Swal.fire({
          title: "Failed to add new credits.",
          text:
            res.response.data.message ||
            "Something went wrong,please try again later.",
          icon: "error",
          showConfirmButton: false,
          timer: 3000,
        });
      }
      setCreditsData({ credits: 0, remark: "" });
      setCreditsCost(0);
    } else {
      setErr(true);
    }
  };

  const handleTotal = () => {
    let temp = creditsData.credits + props.currentCredits;
    if (temp >= 0) return temp;
    else return creditsData.credits;
  };

  const handleBlur = () => {
    if (creditsData.credits <= 0) {
      setErr(true);
    }
  };
  return (
    <>
      <Backdrop
        sx={styles.backBropStyle} // Ensures it's on top of other elements
        open={isLoading}
      >
        <i className="fa fa-spinner fa-spin fa-3x text-black" />
      </Backdrop>
      <RSModal
        isOpen={props.isOpen}
        toggle={props.handleClose}
        centered
        backdrop={"static"}
      >
        <ModalHeader toggle={props.handleClose}>Add Credits</ModalHeader>
        <ModalBody>
          <Form onSubmit={(e) => handleSubmit(e)} autoComplete="off">
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Label tag={"h5"}>
                      Last recharged on{" "}
                      {props.lastRenewedDate
                        ? convertUTCToLocal(props.lastRenewedDate)
                        : "NA"}
                    </Label>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Label className="form-control-label pb-2" xs={3} sm={3}>
                    Credits
                    <strong style={{ color: "red" }}> *</strong>{" "}
                  </Label>
                  <Col xs={7} sm={7} md={7} lg={7}>
                    <div className="d-flex flex-column ml--3">
                      <Input
                        type="tel"
                        value={creditsData.credits || ""}
                        name="credits"
                        onChange={handleInput}
                        onBlur={handleBlur}
                        placeholder="Add Credits"
                        className={err ? "is-invalid" : ""}
                      />
                      {err && (
                        <small className="text-danger mt-2">
                          Please enter valid credits.
                        </small>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Label className="form-control-label pb-2" xs={3} sm={3}>
                    Remark
                  </Label>
                  <Col xs={7} sm={7} md={7} lg={7}>
                    <div className="d-flex flex-column ml--3">
                      <Input
                        type="textarea"
                        value={creditsData.remark}
                        name="remark"
                        maxLength={500}
                        onChange={handleInput}
                        placeholder="Add Remark"
                      />
                    </div>
                  </Col>
                </Row>
                <Card className="m-0 mt-3">
                  <CardBody>
                    <Col className="py-2">
                      <Row className="d-flex justify-content-between">
                        <Col xs={6} sm={6} md={6}>
                          <Label>Current Credits&nbsp;</Label>
                        </Col>
                        <Col>
                          <Label>:</Label>
                        </Col>
                        <Col>
                          <Label>{props.currentCredits}</Label>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between">
                        <Col xs={6} sm={6} md={6}>
                          <Label>Adding New Credits&nbsp;</Label>
                        </Col>
                        <Col>
                          <Label>:</Label>
                        </Col>
                        <Col>
                          <Label>{creditsData.credits}</Label>
                        </Col>
                      </Row>
                    </Col>
                    <CardFooter className="px-3 py-2">
                      <Row className="d-flex justify-content-between">
                        <Col xs={6} sm={6} md={6}>
                          <Label>Total Credits&nbsp;</Label>
                        </Col>
                        <Col>
                          <Label>:</Label>
                        </Col>
                        <Col>
                          <Label>{handleTotal()}</Label>
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-between mt-2">
                        <Col xs={6} sm={6} md={6}>
                          <Label>Total Cost of New Credits&nbsp;</Label>
                        </Col>
                        <Col>
                          <Label>:</Label>
                        </Col>
                        <Col>
                          <Label className="ml--2">
                            {props.currency === "INR" ? "₹" : "$"}
                          </Label>
                          <Label>{creditsCost}</Label>
                        </Col>
                      </Row>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="gray" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button
            color="info"
            type="submit"
            onClick={handleSubmit}
            disabled={!isValid || err || creditsData.credits === 0} // Disable button if not valid
          >
            Add Credits
          </Button>
        </ModalFooter>
      </RSModal>
    </>
  );
}

export default AddCredits;

const styles = {
  backBropStyle: { color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 },
};
