import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
  CardBody,
} from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation, useNavigate } from "react-router-dom";
import { getTenantList } from "action/plans";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "components/CommonComponents/Pagination";
import FilterOptions from "./FilterOptions";
import Swal from "sweetalert2";
import { changeTenantStatus } from "action/plans";
import { getPlanWiseUsers } from "action/plans";
import { GetTenants } from "constants/ReducerConstants/Constants";
import { convertUTCToLocal } from "../../CommonFunctions";
import { cyanBlue } from "pages/TenantDetails/constants/Constants";
import { changeAccountType } from "action/plans";

function TenantTab({ activeTab }) {
  const location = useLocation();
  const planName = location.state?.planName;
  const [sortParams, setSortParam] = useState(
    `{"sortBy":"ID","sortType":"desc"}`
  );
  const [queryString, setQueryString] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState({
    pageLoader: true,
    switchLoading: false,
    switchId: null,
  });
  const [error, setError] = useState(false);
  const tenantData = useSelector((state) => state.TenantReducer.tenantList);
  const [pageNum, setPageNum] = useState(1);
  const [searchData, setSearchData] = React.useState({
    planName: "",
    status: "",
    countryName: "",
    startDate: "",
    endDate: "",
    email: "",
    name: "",
    accType: "EXTERNAL",
    lastLoginDay: [],
  });
  const [copiedRowId, setCopiedRowId] = useState(null);

  React.useEffect(() => {
    if (activeTab === "Tenants") getPlanWiseUsers(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    dispatch({ type: GetTenants, payload: {} });
    setIsLoading((prev) => ({ ...prev, pageLoader: true }));
    if (activeTab === "Dashboard") {
      let tempQuery = [
        {
          field: "status",
          condition: "in",
          filterValues: ["ACTIVE", "NOT_VERIFIED"],
        },
      ];
      let tempTextQuery = JSON.stringify(tempQuery);
      getTenantList(dispatch, tempTextQuery, 1, sortParams);
      return;
    }
    // getTenantList(dispatch, queryString, pageNum, sortParams);
    handleSearch();
  }, [dispatch, queryString]);

  useEffect(() => {
    if (planName) {
      handlePlanChange(planName);
      handleSearch();
    }
  }, [planName]);

  useEffect(() => {
    setIsLoading((prev) => ({ ...prev, pageLoader: true }));
    getTenantList(dispatch, queryString, pageNum, sortParams);
  }, [pageNum, sortParams]);

  React.useEffect(() => {
    if (tenantData?.code) {
      if (tenantData?.code === 200) {
        setError(false);
        setIsLoading((prev) => ({ ...prev, pageLoader: false }));
      } else {
        setError(true);
        setIsLoading((prev) => ({ ...prev, pageLoader: false }));
      }
    } else {
      setError(false);
      setIsLoading((prev) => ({ ...prev, pageLoader: true }));
    }
  }, [tenantData]);

  const handleCountry = (value) => {
    if (value?.name) return value.name;
    else return <div className="ml-3">-</div>;
  };

  const sortTable = (type, colName) => {
    let sortParamObj;
    switch (colName.sortField) {
      case "plan": {
        sortParamObj = { sortBy: "planName", sortType: colName.sortOrder };
        break;
      }
      case "countryResponse": {
        sortParamObj = { sortBy: "countryName", sortType: colName.sortOrder };
        break;
      }
      default: {
        sortParamObj = {
          sortBy: colName.sortField,
          sortType: colName.sortOrder,
        };
      }
    }

    const sortParamString = JSON.stringify(sortParamObj);
    setSortParam(sortParamString);
  };

  const handleStatus = (cell, row) => {
    switch (cell) {
      case "ACTIVE": {
        return (
          <div>
            <i
              className="fa-solid fa-user-check text-success "
              aria-hidden="true"
            ></i>{" "}
            <span className="text-green">Active</span>
          </div>
        );
      }
      case "INACTIVE": {
        return (
          <div>
            <i
              id={`tooltipEdit${row.ID}`}
              className="fa-solid fa-user-xmark text-danger "
            ></i>{" "}
            <span className="text-red">Inactive</span>
          </div>
        );
      }
      case "NOT_VERIFIED": {
        return (
          <div>
            <i
              id={`tooltipEdit${row.ID}`}
              className="fa-solid fa-user-slash text-warning "
            ></i>
            <span className="text-red"> Not Verified</span>
          </div>
        );
      }
      case "DELETED": {
        return (
          <div>
            <i
              id={`tooltipEdit${row.ID}`}
              className="fa-solid fa-user-minus text-danger"
            ></i>
            <span className="text-red"> Deleted</span>
          </div>
        );
      }
      default:
        return cell;
    }
  };

  const handleTenantStatus = async (row, status) => {
    Swal.fire({
      text: `Are you sure you want to ${
        status !== "ACTIVE" ? "Delete" : "Active"
      }. "${row.firstName ? row.firstName + row.lastName : row.email}" ?`,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      icon: "warning",
      // focusConfirm: false,
      focusCancel: true,
      customClass: {
        confirmButton: "bg-info",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        changeStatus(row, status);
      }
    });
  };

  const changeStatus = async (row, status) => {
    const res = await changeTenantStatus(row.ID, { status: status });
    if (res.code === 200) {
      getTenantList(dispatch, queryString, pageNum, sortParams);
      Swal.fire({
        title: `${status !== "ACTIVE" ? "Delete" : "Active"} Successfully`,
        text: `"${
          row.firstName ? row.firstName + row.lastName : row.email
        }"is ${status !== "ACTIVE" ? "Delete" : "Active"} Successfully`,
        icon: "success",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      getTenantList(dispatch, queryString, pageNum, sortParams);
      Swal.fire({
        title: "Something Went Wrong!",
        text: `Couldn't delete "${
          row.firstName ? row.firstName + row.lastName : row.email
        }".`,
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
      });
    }
  };

  const handlePlanChange = (value) => {
    setSearchData((prevData) => ({
      ...prevData,
      planName: value || "",
    }));
  };

  const handleStatusChange = (selectedOption) => {
    setSearchData((prevData) => ({
      ...prevData,
      status: selectedOption?.value || "",
    }));
  };

  const handleAccountTypeChange = (selectedOption) => {
    setSearchData((prevData) => ({
      ...prevData,
      accType: selectedOption?.value || "",
    }));
  };

  const handleNameChange = (input) => {
    setSearchData((prev) => ({ ...prev, name: input.target.value }));
  };

  const handleEmailChange = (input) => {
    setSearchData((prev) => ({ ...prev, email: input.target.value }));
  };

  const handleCountryNameChange = (selectedOption) => {
    setSearchData((prevData) => ({
      ...prevData,
      countryName: selectedOption?.value || "",
    }));
  };

  const handleStartDateChange = (e) => {
    let todaysDate = new Date().toISOString().split("T")[0];

    if (!searchData.endDate)
      setSearchData((prevData) => ({
        ...prevData,
        startDate: e.target.value,
        endDate: todaysDate,
      }));
    else
      setSearchData((prevData) => ({
        ...prevData,
        startDate: e.target.value,
      }));

    if (
      searchData.endDate &&
      new Date(searchData.endDate) < new Date(e.target.value)
    ) {
      setSearchData((prevData) => ({ ...prevData, endDate: "" }));
    }
  };

  const handleEndDateChange = (e) => {
    setSearchData((prevData) => ({ ...prevData, endDate: e.target.value }));
  };

  const handleLastLoginChange = (selectedOption) => {
    setSearchData((prevData) => ({
      ...prevData,
      lastLoginDay: selectedOption?.value || [],
    }));
  };

  const handleSearch = () => {
    setIsLoading((prev) => ({ ...prev, pageLoader: true }));

    const query = Object.keys(searchData)
      .map((key) => {
        if (key === "startDate" || key === "endDate") {
          return null;
        } else if (searchData[key]) {
          if (key === "status" || key === "accType") {
            return {
              field: key,
              condition: "eq",
              filterValues: [searchData[key]],
            };
          } else if (key === "lastLoginDay") {
            return {
              field: key,
              condition: "btw",
              filterValues: searchData[key],
            };
          } else {
            return {
              field: key,
              condition: "like",
              filterValues: [searchData[key]],
            };
          }
        }
        return null;
      })
      .filter((item) => item !== null);

    if (searchData.startDate && searchData.endDate) {
      query.push({
        field: "registeredDate",
        condition: "btw",
        filterValues: [searchData.startDate, searchData.endDate],
      });
    } else if (searchData.startDate && !searchData.endDate) {
      query.push({
        field: "registeredDate",
        condition: "gt",
        filterValues: [searchData.startDate],
      });
    } else if (!searchData.startDate && searchData.endDate) {
      query.push({
        field: "registeredDate",
        condition: "lt",
        filterValues: [searchData.endDate],
      });
    }
    if (queryString !== JSON.stringify(query))
      setIsLoading((prev) => ({ ...prev, pageLoader: true }));

    setQueryString(JSON.stringify(query));
    setPageNum(1);
    getTenantList(dispatch, JSON.stringify(query), 1, sortParams);
    //     setIsLoading((prev) => ({ ...prev, pageLoader: false }));
  };

  const handleClear = () => {
    setSearchData({
      planName: "",
      status: "",
      countryName: "",
      startDate: "",
      endDate: "",
      email: "",
      name: "",
      accType: "EXTERNAL",
      lastLoginDay: [],
    });
    setIsLoading((prev) => ({ ...prev, pageLoader: true }));
    setPageNum(1);
    setQueryString("");
    // getTenantList(dispatch, JSON.stringify(query), pageNum, sortParams);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleChangeTenantAccountType = async (e, row) => {
    setIsLoading((prev) => ({
      ...prev,
      switchLoading: true,
      switchId: row.ID,
    }));
    let accountType = {
      accType: "",
    };
    if (e.target.checked) {
      accountType = {
        accType: "INTERNAL",
      };
    } else {
      accountType = {
        accType: "EXTERNAL",
      };
    }
    const res = await changeAccountType(row.ID, accountType);
    setTimeout(() => {
      setIsLoading((prev) => ({
        ...prev,
        switchLoading: false,
        switchId: null,
      }));
    }, 1000);
    if (res?.status === 200)
      getTenantList(dispatch, queryString, pageNum, sortParams);
    else {
      Swal.fire({
        title: "Something went wrong",
        text: "Please try again later",
        icon: "error",
        timer: 3000,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  };

  const handleCopy = (email, rowId) => {
    navigator.clipboard.writeText(email);
    setCopiedRowId(rowId);
    setTimeout(() => setCopiedRowId(null), 1000);
  };

  return (
    <>
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardHeader>
            <Row md={12} lg={12} sm={12}>
              <Col md={10} lg={10} sm={10}>
                <h3 className="mb-0 d-flex">
                  {activeTab === "Dashboard" ? "Tenant List" : "Tenants"}
                  {activeTab === "Dashboard" ? (
                    <>
                      {tenantData?.data?.totalRecords && (
                        <span className="mb-0 h3">
                          &nbsp;({tenantData?.data?.totalRecords})
                        </span>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </h3>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className={activeTab === "Dashboard" ? "mt--4" : ""}>
            {activeTab === "Tenants" && (
              <Col lg="12" md="12" sm="12" className="mb-3">
                <FilterOptions
                  pageNum={pageNum}
                  searchData={searchData}
                  handlePlanChange={handlePlanChange}
                  handleClear={handleClear}
                  handleStatusChange={handleStatusChange}
                  handleCountryNameChange={handleCountryNameChange}
                  handleStartDateChange={handleStartDateChange}
                  handleEndDateChange={handleEndDateChange}
                  handleSearch={handleSearch}
                  handleEmailChange={handleEmailChange}
                  handleNameChange={handleNameChange}
                  handleKeyPress={handleKeyPress}
                  handleAccountTypeChange={handleAccountTypeChange}
                  handleLastLoginChange={handleLastLoginChange}
                />
              </Col>
            )}
            {isLoading.pageLoader ? (
              <div style={style.loaderContainer}>
                <i
                  className="fa fa-spinner fa-spin fa-2xl"
                  style={style.loaderColor}
                />
              </div>
            ) : error ? (
              <>
                <div style={style.loaderContainer}>Something went wrong</div>
              </>
            ) : tenantData?.data?.tenants ? (
              <>
                <ToolkitProvider
                  data={tenantData?.data?.tenants || []}
                  keyField="ID"
                  columns={[
                    { dataField: "ID", text: "ID", sort: false },
                    {
                      dataField: "organizationName",
                      text: "Organization - Name - Email",
                      events: { onClick: (e) => e.stopPropagation() },
                      sort: false,
                      style: style.copyEmailStyle,
                      formatter: (cell, row, rowIndex) => (
                        <div className="d-flex flex-column">
                          <span>
                            Org:{" "}
                            {row.organizationName ? (
                              row.organizationName.length >= 40 ? (
                                <>
                                  <span id={`tooltipOrg-${rowIndex}`}>
                                    {row.organizationName.substring(0, 35) +
                                      "..."}
                                  </span>
                                  <UncontrolledTooltip
                                    target={`tooltipOrg-${rowIndex}`}
                                  >
                                    {row.organizationName}
                                  </UncontrolledTooltip>
                                </>
                              ) : (
                                row.organizationName
                              )
                            ) : (
                              <span className="text-center">NA</span>
                            )}
                          </span>
                          <span className="mt-1">
                            Name:{" "}
                            {row.firstName || row.lastName ? (
                              (row.firstName + " " + row.lastName).trim()
                                .length >= 40 ? (
                                <>
                                  <span id={`tooltipName-${rowIndex}`}>
                                    {(
                                      row.firstName +
                                      " " +
                                      row.lastName
                                    ).substring(0, 37) + "..."}
                                  </span>
                                  <UncontrolledTooltip
                                    target={`tooltipName-${rowIndex}`}
                                  >
                                    {row.firstName + " " + row.lastName}
                                  </UncontrolledTooltip>
                                </>
                              ) : (
                                row.firstName + " " + row.lastName
                              )
                            ) : (
                              <span className="text-center">NA</span>
                            )}
                          </span>
                          <span className="d-flex align-items-center mt-1">
                            <span>
                              Email:{" "}
                              {row.email ? (
                                row.email.length >= 40 ? (
                                  <>
                                    <span id={`tooltipEmail-${rowIndex}`}>
                                      {row.email.substring(0, 35) + "..."}
                                    </span>
                                    <UncontrolledTooltip
                                      target={`tooltipEmail-${rowIndex}`}
                                    >
                                      {row.email}
                                    </UncontrolledTooltip>
                                  </>
                                ) : (
                                  row.email
                                )
                              ) : (
                                <span className="text-center">NA</span>
                              )}
                            </span>

                            {row.email && (
                              <>
                                {copiedRowId === row.ID ? (
                                  <span className="font-weight-bold pl-2">
                                    Copied!
                                  </span>
                                ) : (
                                  <i
                                    onClick={() =>
                                      handleCopy(row.email, row.ID)
                                    }
                                    className="fa-regular fa-copy ml-2"
                                    style={{ cursor: "pointer" }}
                                  ></i>
                                )}
                              </>
                            )}
                          </span>
                        </div>
                      ),
                    },
                    {
                      dataField: "plan",
                      text: "Plan",
                      sort: true,
                      formatter: (cell) =>
                        cell === "" ? (
                          <div className="text-center h-0">-</div>
                        ) : (
                          cell
                        ),
                    },
                    {
                      dataField: "registeredDate",
                      text: "Registered Date",
                      sort: true,
                      formatter: (cell) => convertUTCToLocal(cell),
                    },
                    {
                      dataField: "countryResponse",
                      text: "Country",
                      sort: true,
                      formatter: (cell) => handleCountry(cell),
                    },
                    {
                      dataField: "totalCampaign",
                      text: "Campaigns",
                      // sort: true,
                      formatter: (cell) =>
                        cell === "" ? (
                          <div className="text-center h-0">-</div>
                        ) : (
                          cell
                        ),
                    },
                    {
                      dataField: "creditSpent",
                      text: "Credits Spent",
                      // sort: true,
                      formatter: (cell) =>
                        cell === "" ? (
                          <div className="text-center h-0">-</div>
                        ) : (
                          cell
                        ),
                    },
                    {
                      dataField: "subUsers",
                      text: "Sub Users",
                      sort: true,
                      formatter: (cell) =>
                        cell === "" ? (
                          <div className="text-center h-0">-</div>
                        ) : (
                          cell
                        ),
                    },
                    {
                      dataField: "lastLoginAt",
                      text: "Last Login",
                      sort: true,
                      align: "left",
                      headerAlign: "center",
                      formatter: (cell) =>
                        cell === "" ? (
                          <div className="text-center h-0">-</div>
                        ) : (
                          cell
                        ),
                    },
                    {
                      dataField: "status",
                      text: "Status",
                      sort: true,
                      align: "left",
                      headerAlign: "center",
                      formatter: (cell, row) => handleStatus(cell, row),
                    },

                    {
                      dataField: "accType",
                      text: "Actions",
                      align: "center",
                      headerAlign: "center",
                      events: { onClick: (e) => e.stopPropagation() },
                      formatter: (cell, row) => (
                        <div
                          style={style.archiveTenantAction}
                          onClick={(e) => e.stopPropagation()}
                        >
                          {row.status === "INACTIVE" ||
                          row.status === "DELETED" ? (
                            <>
                              <a
                                className="table-action table-action-success"
                                id={`tooltipActivate${cell}`}
                                onClick={(e) => {
                                  handleTenantStatus(row, "ACTIVE");
                                }}
                              >
                                <i
                                  className="fa-solid fa-check"
                                  style={{ fontSize: "1.3rem" }}
                                ></i>
                              </a>
                              <UncontrolledTooltip
                                target={`tooltipActivate${cell}`}
                              >
                                Activate Tenant
                              </UncontrolledTooltip>
                              <p className="mt--1">|</p>
                            </>
                          ) : (
                            <>
                              <a
                                className="table-action table-action-delete"
                                id={`tooltipDelete${cell}`}
                                onClick={(e) => {
                                  handleTenantStatus(row, "DELETED");
                                }}
                              >
                                <i
                                  className={"fa-solid fa-trash"}
                                  style={{ fontSize: "1rem" }}
                                ></i>
                              </a>
                              <UncontrolledTooltip
                                target={`tooltipDelete${cell}`}
                              >
                                Delete Tenant
                              </UncontrolledTooltip>
                              <p className="mt--1">|</p>
                            </>
                          )}
                          {isLoading.switchLoading &&
                          isLoading.switchId === row.ID ? (
                            <div>
                              <i
                                className="fa fa-spinner fa-spin fa-xl"
                                style={style.loaderColor}
                              />
                            </div>
                          ) : (
                            <>
                              <label className="custom-toggle custom-toggle-info">
                                <Input
                                  checked={
                                    row.accType !== "EXTERNAL" ? true : false
                                  }
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleChangeTenantAccountType(e, row)
                                  }
                                />
                                <span
                                  className="custom-toggle-slider rounded-circle"
                                  data-label-off="EXT"
                                  data-label-on="INT"
                                ></span>
                              </label>
                            </>
                          )}
                        </div>
                      ),
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        remote={{ sort: true }}
                        onTableChange={sortTable}
                        defaultSortDirection={"asc"}
                        rowEvents={{
                          onClick: (e, row) => {
                            navigate(`/admin/tenants/details`, {
                              state: { id: row.ID },
                            });
                          },
                        }}
                        // selectRow={{
                        //   onSelect: (e, isSelect, rowIndex) =>
                        //     console.log(e, isSelect, rowIndex),
                        // }}
                        rowStyle={{
                          cursor: `pointer`,
                        }}
                      />
                    </div>
                  )}
                </ToolkitProvider>

                {activeTab === "Tenants" && (
                  <div className="d-flex justify-content-between">
                    {tenantData.data.totalRecords && (
                      <p className="font-weight-bold">
                        Tenants: {tenantData.data.totalRecords}
                      </p>
                    )}
                    <CustomPagination
                      totalPages={Math.ceil(
                        tenantData.data.totalRecords /
                          tenantData.data.noOfRecordsPerPage
                      )}
                      setPageNum={setPageNum}
                      activePage={pageNum}
                    />
                  </div>
                )}
              </>
            ) : (
              <div style={style.loaderContainer}>No Records Found</div>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default TenantTab;

const style = {
  loaderColor: { color: cyanBlue },

  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
  activeTenantAction: {
    display: "flex",
    flexDirection: "row",
    columnGap: "0.5rem",
    alignItems: "center",
  },
  archiveTenantAction: {
    display: "flex",
    flexDirection: "row",
    columnGap: "0.5rem",
  },
  copyEmailStyle: { width: "300px", cursor: "default" },
};
