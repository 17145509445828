import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  Container,
  Col,
  Row,
  Input,
  Label,
  CardHeader,
  Button,
  Form,
  FormGroup,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { updatePlan } from "action/plans";
import { useDispatch, useSelector } from "react-redux";
import { getPlan } from "action/plans";
import { cyanBlue } from "pages/TenantDetails/constants/Constants";
import TimelineHeader from "components/Headers/SimpleHeader";

function EditPlan() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = React.useState(true);

  const planId = location.state;
  const planData = useSelector((state) => state.planReducer.planData);

  const [editedPlan, setEditedPlan] = useState({});
  const handleBackButton = () => {
    navigate(-1);
  };
  useEffect(() => {
    getPlan(planId, dispatch);
  }, [dispatch]);

  useEffect(() => {
    setEditedPlan(planData.data);
    if (planData) {
      setIsLoading(false);
    }
  }, [planData.data]);

  const getUpdatedData = (prevPlan, editedPlan) => {
    const planKeys = Object.keys(prevPlan);
    let newPlan = {};
    for (let key of planKeys) {
      if (prevPlan[key] !== editedPlan[key]) {
        if (key === "usdRate") {
          // let usdRateInFloat = parseFloat(editedPlan[key]);
          newPlan = { ...newPlan, [key]: parseFloat(editedPlan[key]) };
        } else {
          newPlan = { ...newPlan, [key]: editedPlan[key] };
        }
      }
    }
    return newPlan;
  };
  // getUpdatedData(planData, editedPlan);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedPlan({ ...editedPlan, [name]: Number(value) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedPlan = getUpdatedData(planData.data, editedPlan);
    // console.log("updatedPlan", updatedPlan);
    const updatedPlanResponse = await updatePlan(editedPlan.ID, updatedPlan);
    if (updatedPlanResponse.code === 200) {
      Swal.fire({
        icon: "success",
        title: "Plan Updated",
        text: `${updatedPlanResponse.message}`,
        showConfirmButton: false,
        timer: 2000,
      });
      setTimeout(() => {
        navigate("/admin/plans");
      }, 2000);
    } else {
      Swal.fire({
        icon: "error",
        title: "Couldn't Update Plan",
        text: `${updatedPlanResponse.message}`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  };
  const handleReadonly = () => {
    return editedPlan?.ID === 1 ? true : false;
  };

  const checkIfAnyFieldChange = () => {
    const oldPlanFields = planData.data;
    for (const key in editedPlan) {
      if (editedPlan[key] !== oldPlanFields[key]) {
        return true;
      }
    }
    return false;
  };
  const handleSubmitBtnDisabled = () => {
    return editedPlan?.credits < 0 ||
      editedPlan?.usdRate < 0 ||
      editedPlan?.subUsersLimit < 0 ||
      editedPlan?.campaignsLimit < 0 ||
      !checkIfAnyFieldChange()
      ? true
      : false;
  };

  return (
    <>
      <TimelineHeader showBack={true} onClickBack={handleBackButton} />
      <Container fluid className="mt--6 ">
        <Card>
          <CardBody>
            <CardHeader className="mb-4 pb-0">
              <h3>Edit Plan</h3>
            </CardHeader>
            {isLoading ? (
              <div style={styles.loaderContainer}>
                <i
                  className="fa fa-spinner fa-spin fa-2xl"
                  style={styles.loaderColor}
                />
              </div>
            ) : planData.code === 200 ? (
              <Row className="d-flex justify-content-center">
                <Col sm={8}>
                  <Form>
                    <FormGroup row>
                      <Label className="form-control-label pb-2" sm={2}>
                        Plan Name
                      </Label>
                      <Col sm={10}>
                        <Input
                          readOnly
                          type="text"
                          name="name"
                          placeholder={"Plan Name"}
                          value={editedPlan?.name}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="form-control-label pb-2" sm={2}>
                        Credits
                      </Label>
                      <Col sm={10}>
                        <Input
                          required
                          type="number"
                          name="credits"
                          placeholder={"Credits"}
                          value={editedPlan?.credits}
                          onChange={handleChange}
                        />
                        {editedPlan?.credits < 0 ? (
                          <small className="text-danger">Invalid Input</small>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="form-control-label pb-2" sm={2}>
                        Price
                      </Label>
                      <Col sm={10}>
                        <InputGroup>
                          <InputGroupText className="bg-secondary">
                            $
                          </InputGroupText>
                          <Input
                            readOnly={handleReadonly()}
                            required
                            type="number"
                            name="usdRate"
                            placeholder={"Price"}
                            value={editedPlan?.usdRate}
                            onChange={handleChange}
                          />
                        </InputGroup>
                        <small className="text-muted">
                          Please Enter Price in USD only
                        </small>{" "}
                        <br />
                        {editedPlan?.usdRate < 0 ? (
                          <small className="text-danger">Invalid Input</small>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="form-control-label pb-2" sm={2}>
                        Duration
                      </Label>
                      <Col sm={10}>
                        <Input readOnly value="1 Month" />
                        <small className="text-muted">Default Duration</small>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="form-control-label pb-2" sm={2}>
                        Campaigns
                      </Label>
                      <Col sm={10}>
                        <Input
                          required
                          type="number"
                          name="campaignsLimit"
                          placeholder={"Campaigns"}
                          value={editedPlan?.campaignsLimit}
                          onChange={handleChange}
                        />
                        {editedPlan?.campaignsLimit < 0 ? (
                          <small className="text-danger">Invalid Input</small>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label className="form-control-label pb-2" sm={2}>
                        Sub-Users
                      </Label>
                      <Col sm={10}>
                        <Input
                          required
                          type="number"
                          name="subUsersLimit"
                          placeholder={"Sub-Users"}
                          value={editedPlan?.subUsersLimit}
                          onChange={handleChange}
                        />
                        {editedPlan?.subUsersLimit < 0 ? (
                          <small className="text-danger">Invalid Input</small>
                        ) : null}
                      </Col>
                    </FormGroup>
                    <Button
                      type="button"
                      // outline
                      color="info"
                      className="float-right"
                      disabled={handleSubmitBtnDisabled()}
                      onClick={(e) => handleSubmit(e)}
                    >
                      Update
                    </Button>
                  </Form>
                </Col>
              </Row>
            ) : (
              <div style={styles.loaderContainer}>Something went wrong</div>
            )}
          </CardBody>
        </Card>
      </Container>
    </>
  );
}

export default EditPlan;

const styles = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
};
