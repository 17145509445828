export const cyanBlue = "#11CDEF";
export const deepBlue = "#0000ffcc";
export const subUserData = [
  {
    planid: 1,
    subusername: "John Hell",
    email: "john@gmail.com",
    time: "2024/12/15 07:50",
    status: "In-active",
  },
  {
    planid: 2,
    subusername: "Alice Johnson",
    email: "alice@gmail.com",
    time: "2024/12/20 14:15",
    status: "Active",
  },
  {
    planid: 3,
    subusername: "Robert Smith",
    email: "robert@gmail.com",
    time: "2024/11/18 09:10",
    status: "In-active",
  },
  {
    planid: 4,
    subusername: "Michael Brown",
    email: "michael@gmail.com",
    time: "2024/12/10 12:30",
    status: "Active",
  },
  {
    planid: 5,
    subusername: "Emily Davis",
    email: "emily@gmail.com",
    time: "2024/12/05 08:50",
    status: "In-active",
  },
  {
    planid: 6,
    subusername: "Jessica Wilson",
    email: "jessica@gmail.com",
    time: "2024/01/01 06:45",
    status: "Active",
  },
  {
    planid: 7,
    subusername: "Daniel Martinez",
    email: "daniel@gmail.com",
    time: "2024/11/23 17:20",
    status: "Active",
  },
  {
    planid: 8,
    subusername: "Laura Thompson",
    email: "laura@gmail.com",
    time: "2024/12/12 13:05",
    status: "In-active",
  },
  {
    planid: 9,
    subusername: "David Garcia",
    email: "david@gmail.com",
    time: "2024/12/02 16:55",
    status: "Active",
  },
  {
    planid: 10,
    subusername: "Sarah Clark",
    email: "sarah@gmail.com",
    time: "2024/11/07 11:30",
    status: "In-active",
  },
];

export const campaignData = [
  {
    campaignname: "Summer Sale",
    totalattendace: 1200,
    creditsused: 300,
    startdate: "2024/06/01 1:23:57",
    enddate: "2024/06/30",
    status: "Completed",
  },
  {
    campaignname: "Winter Promo",
    totalattendace: 800,
    creditsused: 150,
    startdate: "2024/12/01",
    enddate: "2024/12/31",
    status: "Ongoing",
  },
  {
    campaignname: "Back to School",
    totalattendace: 1500,
    creditsused: 400,
    startdate: "2024/08/01",
    enddate: "2024/08/31",
    status: "Completed",
  },
  {
    campaignname: "Black Friday Sale",
    totalattendace: 2000,
    creditsused: 500,
    startdate: "2024/11/24",
    enddate: "2024/11/30",
    status: "Completed",
  },
  {
    campaignname: "New Year Bash",
    totalattendace: 1000,
    creditsused: 250,
    startdate: "2025/01/01",
    enddate: "2025/01/31",
    status: "Scheduled",
  },
  {
    campaignname: "Spring Clearance",
    totalattendace: 1100,
    creditsused: 200,
    startdate: "2024/03/01",
    enddate: "2024/03/31",
    status: "Completed",
  },
  {
    campaignname: "Holiday Specials",
    totalattendace: 950,
    creditsused: 180,
    startdate: "2024/11/15",
    enddate: "2024/12/15",
    status: "Ongoing",
  },
  {
    campaignname: "Tech Expo",
    totalattendace: 1300,
    creditsused: 350,
    startdate: "2024/09/20",
    enddate: "2024/09/25",
    status: "Completed",
  },
  {
    campaignname: "Fitness Frenzy",
    totalattendace: 700,
    creditsused: 120,
    startdate: "2024/07/01",
    enddate: "2024/07/31",
    status: "Completed",
  },
  {
    campaignname: "Summer Concert",
    totalattendace: 1800,
    creditsused: 400,
    startdate: "2024/06/15",
    enddate: "2024/06/30",
    status: "Completed",
  },
];

export const PaymentHistoryData = [
  {
    planID: "1",
    date: "1 Aug 2024 10:00 am",
    plan: "Pro",
    planprice: "$9.95",
    credits: "1000",
    downloadinvoice: "Download Link 1",
    id: "3d96",
  },
  {
    planID: "2",
    date: "15 Aug 2024 02:15 pm",
    plan: "Starter",
    planprice: "$49.95",
    credits: "10000",
    downloadinvoice: "Download Link 2",
    id: "f8f3",
  },
  {
    planID: "3",
    date: "1 Sep 2024 09:30 am",
    plan: "Pro Plus",
    planprice: "$99.95",
    credits: "30000",
    downloadinvoice: "Download Link 3",
    id: "b5ee",
  },
  {
    planID: "4",
    date: "10 Sep 2024 04:00 pm",
    plan: "Premium",
    planprice: "$199.95",
    credits: "100000",
    downloadinvoice: "Download Link 4",
    id: "3b9f",
  },
  {
    planID: "5",
    date: "1 Oct 2024 08:15 am",
    plan: "Elite",
    planprice: "Free",
    credits: "100",
    downloadinvoice: "Download Link 5",
    id: "f037",
  },
  {
    planID: "6",
    date: "15 Oct 2024 11:30 am",
    plan: "Business",
    planprice: "Free",
    credits: "100",
    downloadinvoice: "Download Link 6",
    id: "763d",
  },
  {
    planID: "7",
    date: "1 Nov 2024 03:45 pm",
    plan: "Enterprise",
    planprice: "Free",
    credits: "100",
    downloadinvoice: "Download Link 7",
    id: "971c",
  },
  {
    planID: "8",
    date: "15 Nov 2024 07:00 am",
    plan: "Ultimate",
    planprice: "Free",
    credits: "100",
    downloadinvoice: "Download Link 8",
    id: "c35e",
  },
  {
    planID: "9",
    date: "1 Dec 2024 12:20 pm",
    plan: "Custom",
    planprice: "Free",
    credits: "100",
    downloadinvoice: "Download Link 9",
    id: "1a04",
  },
  {
    planID: "10",
    date: "15 Dec 2024 11:30 am",
    plan: "Basic",
    planprice: "Free",
    credits: "100",
    downloadinvoice: "Download Link 10",
    id: "2cd8",
  },
];

export const tenantList = [
  {
    cmpName: "Scalent",
    registerOn: new Date(),
    country: "India",
    totalCampaign: 2300,
    credits: 1800,
    subUsers: 500,
    plan: "PRO",
    status: "Active",
  },
  {
    cmpName: "Google",
    registerOn: new Date(),
    country: "India",
    totalCampaign: 3600,
    credits: 3000,
    subUsers: 1500,
    plan: "ELITE",
    status: "Active",
  },
  {
    cmpName: "Denial Services",
    registerOn: new Date(),
    country: "India",
    totalCampaign: 1800,
    credits: 300,
    subUsers: 100,
    plan: "STARTER",
    status: "In-Active",
  },
  {
    cmpName: "IT Solutions",
    registerOn: new Date(),
    country: "India",
    totalCampaign: 800,
    credits: 400,
    subUsers: 300,
    plan: "STARTER",
    status: "Active",
  },
  {
    cmpName: "ViberNet",
    registerOn: new Date(),
    country: "India",
    totalCampaign: 1800,
    credits: 300,
    subUsers: 100,
    plan: "PREMIUM",
    status: "In-Active",
  },
];

export const tenantDetails = [
  {
    ID: 6,
    name: "Self Hosted",
    usdRate: "NA",
    duration: "NA",
    credits: "Unlimited",
    campaignLimit: "Unlimited",
    field1: "Full Control",
    field2: "Full Customization",
    field3: "On Call Support",
    field4: "Customization Option",
  },
];
