import { GetPlansList } from "constants/ReducerConstants/Constants";
import { GetPlanWiseUsers } from "constants/ReducerConstants/Constants";
import { GetPlan } from "constants/ReducerConstants/Constants";

const InitialState = {
  plansList: {},
  plansTenantList: {},
  planData: {},
};

const planReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GetPlansList: {
      return { ...state, plansList: action.payload };
    }
    case GetPlanWiseUsers: {
      return { ...state, plansTenantList: action.payload };
    }
    case GetPlan: {
      return { ...state, planData: action.payload };
    }
    default:
      return state;
  }
};

export default planReducer;
