import { GetTenants } from "constants/ReducerConstants/Constants";

const InitialState = { tenantList: {} };

const TenantReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GetTenants: {
      return { ...state, tenantList: action.payload };
    }
    default:
      return state;
  }
};

export default TenantReducer;
