import { getCountryList } from "action/plans";
import TimelineHeader from "components/Headers/SimpleHeader";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
// import { cyanBlue } from "pages/Details/constants/Constants";
import { cyanBlue } from "pages/TenantDetails/constants/Constants";
import Swal from "sweetalert2";
import { Divider } from "@mui/material";
function AbsentUsers() {
  // const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const tempData = [
    {
      id: 1,
      name: "John Doe",
      email: "johndoe@example.com",
      phone: "123-456-7890",
      registeredon: "2023-01-15",
      dayssincelastlogin: 10,
      plan: "Premium",
      remark: "Active user with good feedback",
      actions: "",
    },
    {
      id: 2,
      name: "Jane Smith",
      email: "janesmith@example.com",
      phone: "234-567-8901",
      registeredon: "2022-12-05",
      dayssincelastlogin: 45,
      plan: "Free",
      remark: "Considering upgrade",
      actions: "",
    },
    {
      id: 3,
      name: "Alice Johnson",
      email: "alicej@example.com",
      phone: "345-678-9012",
      registeredon: "2023-03-20",
      dayssincelastlogin: 5,
      plan: "Premium",
      remark: "Frequent user, very satisfied with service",
      actions: "",
    },
    {
      id: 4,
      name: "Bob Brown",
      email: "bobbrown@example.com",
      phone: "456-789-0123",
      registeredon: "2023-04-10",
      dayssincelastlogin: 20,
      plan: "Free",
      remark: "Occasional issues with login",
      actions: "",
    },
    {
      id: 5,
      name: "Charlie Green",
      email: "charliegreen@example.com",
      phone: "567-890-1234",
      registeredon: "2023-06-18",
      dayssincelastlogin: 2,
      plan: "Premium",
      remark: "Recently joined, exploring features",
      actions: "",
    },
    {
      id: 6,
      name: "Daisy White",
      email: "daisyw@example.com",
      phone: "678-901-2345",
      registeredon: "2023-02-08",
      dayssincelastlogin: 30,
      plan: "Free",
      remark: "Limited usage, considering alternatives",
      actions: "",
    },
    {
      id: 7,
      name: "Eve Black",
      email: "eveblack@example.com",
      phone: "789-012-3456",
      registeredon: "2023-07-22",
      dayssincelastlogin: 15,
      plan: "Premium",
      remark: "Happy with current plan",
      actions: "",
    },
    {
      id: 8,
      name: "Frank Grey",
      email: "frankgrey@example.com",
      phone: "890-123-4567",
      registeredon: "2022-10-13",
      dayssincelastlogin: 200,
      plan: "Free",
      remark: "Rarely logs in",
      actions: "",
    },
    {
      id: 9,
      name: "Grace Purple",
      email: "gracep@example.com",
      phone: "901-234-5678",
      registeredon: "2023-09-05",
      dayssincelastlogin: 1,
      plan: "Premium",
      remark: "Recently upgraded plan",
      actions: "",
    },
    {
      id: 10,
      name: "Harry Gold",
      email: "harryg@example.com",
      phone: "012-345-6789",
      registeredon: "2023-05-12",
      dayssincelastlogin: 7,
      plan: "Free",
      remark: "Undecided about plan renewal",
      actions: "",
    },
  ];

  const columns = [
    {
      dataField: "id",
      text: "Sr.no",
      sort: true,
    },
    {
      dataField: "name",
      text: "name",
      sort: true,
    },
    {
      dataField: "email",
      text: "email",
      sort: true,
    },
    {
      dataField: "phone",
      text: "phone number",
      sort: true,
    },
    {
      dataField: "registeredon",
      text: "Registered On",
      sort: true,
    },
    {
      dataField: "dayssincelastlogin",
      text: "Days since last login",
      sort: true,
    },
    {
      dataField: "plan",
      text: "plan",
      sort: true,
    },
    {
      dataField: "remark",
      text: "remark",
      sort: true,
      style: { width: "10rem" },
      formatter: (cell, row) => {
        if (cell.length >= 10) {
          let temp = cell.substring(0, 10);
          return (
            <>
              <span>
                {temp}
                <span
                  style={{ textDecoration: "none", cursor: "pointer" }}
                  onClick={() => toggle()}
                  onMouseOver={(e) =>
                    (e.target.style.textDecoration = "underline")
                  }
                  onMouseOut={(e) => (e.target.style.textDecoration = "none")}
                  className="text-blue"
                >
                  ...view more
                </span>
              </span>
            </>
          );
        } else return cell;
      },
    },
    {
      dataField: "dayssincelastlogin",
      text: "actions",
      sort: true,
      headerAlign: "center",
      formatter: (cell) => {
        return (
          <div style={style.actionColumn}>
            <a className="table-action table-action-info">
              <i
                style={style.cursor}
                id={`tooltipNote${cell}`}
                onClick={() => handlePopUp("note")}
                className="fa-solid fa-pen-to-square"
              ></i>
            </a>
            <UncontrolledTooltip target={`tooltipNote${cell}`}>
              Add Note
            </UncontrolledTooltip>
            |
            <a
              className="table-action table-action-success"
              style={style.cursor}
            >
              <i
                id={`tooltipReminder${cell}`}
                onClick={() => handlePopUp("reminder")}
                style={style.cursor}
                className="fa-solid fa-clock-rotate-left"
              ></i>
            </a>
            <UncontrolledTooltip target={`tooltipReminder${cell}`}>
              Send a Reminder
            </UncontrolledTooltip>
            |
            <a className="table-action table-action-blue">
              <i
                id={`tooltipPayment${cell}`}
                onClick={() => handlePopUp("payment")}
                style={style.cursor}
                className="fa-solid fa-link"
              ></i>
            </a>
            <UncontrolledTooltip target={`tooltipPayment${cell}`}>
              Send Payment Link
            </UncontrolledTooltip>
            |
            <a className="table-action table-action-danger">
              <i
                id={`tooltipinActive${cell}`}
                onClick={() => handlePopUp("make_inactive")}
                style={style.cursor}
                className="fa-solid fa-circle-xmark"
              ></i>
            </a>
            <UncontrolledTooltip target={`tooltipinActive${cell}`}>
              Make Inactive
            </UncontrolledTooltip>
          </div>
        );
      },
    },
  ];

  const handlePopUp = async (flag) => {
    switch (flag) {
      case "note": {
        const { value: text } = await Swal.fire({
          input: "textarea",
          inputLabel: "Add Note",
          inputPlaceholder: "Type your message here",
          inputAttributes: {
            "aria-label": "Type your message here",
          },
          showCancelButton: true,
          customClass: {
            confirmButton: "bg-info",
            popup: "px-0",
            inputLabel: "font-weight-bold",
            validationMessage: "validation-message",
          },
          preConfirm: (value) => {
            if (!value || value.trim() === "") {
              Swal.showValidationMessage(`<span>Remark cannot be empty</span>`);

              return false;
            }
            return value;
          },
        });

        if (text) {
          Swal.fire({
            icon: "success",
            title: `"${text}" Remark added.`,
            timer: 3000,
            showConfirmButton: false,
            showCancelButton: false,
          });
        }
        break;
      }

      case "reminder": {
        Swal.fire({
          title: "Reminder Sent Successfully",
          icon: "success",
          timer: 3000,
          showCancelButton: false,
          showConfirmButton: false,
        });
        break;
      }

      case "make_inactive": {
        const result = await Swal.fire({
          title: "Are You Sure?",
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          focusCancel: true,
          customClass: {
            confirmButton: "bg-info",
          },
        });

        if (result.isConfirmed) {
          Swal.fire({
            title: "Inactivated Successfully",
            icon: "success",
            showCancelButton: false,
            showConfirmButton: false,
            timer: 3000,
          });
        }
        break;
      }
      case "payment": {
        Swal.fire({
          title: "Payment Link Sent Successfully",
          icon: "success",
          timer: 3000,
          showCancelButton: false,
          showConfirmButton: false,
        });
        break;
      }

      default:
        break;
    }
  };

  //   const countryList = useSelector(
  //     (state) => state.CountryReducer.countryList.data
  //   );
  //   const [searchData, setSearchData] = React.useState({
  //     countryName: 0,
  //     startDate: "",
  //     endDate: "",
  //     referrer: "",
  //   });

  //   useEffect(() => {
  //     getCountryList(dispatch);
  //   }, []);

  //   const handleCountryNameChange = (selectedOption) => {
  //     setSearchData((prevData) => ({
  //       ...prevData,
  //       countryName: selectedOption.ID || "",
  //     }));
  //   };

  //   const handleStartDateChange = (e) => {
  //     let todaysDate = new Date().toISOString().split("T")[0];

  //     if (!searchData.endDate)
  //       setSearchData((prevData) => ({
  //         ...prevData,
  //         startDate: e.target.value,
  //         endDate: todaysDate,
  //       }));
  //     else
  //       setSearchData((prevData) => ({
  //         ...prevData,
  //         startDate: e.target.value,
  //       }));

  //     if (
  //       searchData.endDate &&
  //       new Date(searchData.endDate) < new Date(e.target.value)
  //     ) {
  //       setSearchData((prevData) => ({ ...prevData, endDate: "" }));
  //     }
  //   };

  //   const handleEndDateChange = (e) => {
  //     setSearchData((prevData) => ({ ...prevData, endDate: e.target.value }));
  //   };

  //   const handleReferrerChange = (e) => {
  //     setSearchData((prev) => ({ ...prev, referrer: e.target.value }));
  //   };

  //   const handleSubmit = () => {
  //     const textQuery = JSON.stringify(searchData);
  //   };

  //   const handleClear = () => {
  //     setSearchData({
  //       countryName: 0,
  //       startDate: "",
  //       endDate: "",
  //       referrer: "",
  //     });
  //   };

  return (
    <>
      <TimelineHeader name="Idle Users" />
      <Container fluid className="mt--5">
        <Card>
          <CardBody className="mb--4 pb-0">
            <small>Note: This all is a static data</small>
            {/* <Row>
              <Col lg="2" md="2" sm="12">
                <Input
                  type="text"
                  style={{ height: "38px" }}
                  placeholder="Search Referrer"
                  value={searchData.referrer}
                  onChange={handleReferrerChange}
                />
              </Col>
              <Col className="p-md-0 px-3" lg="2" md="2" sm="12">
                <Select
                  options={countryList}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.ID}
                  onChange={handleCountryNameChange}
                  placeholder="Select Country"
                  isClearable
                  styles={customStyles}
                />
              </Col>
              <Col>
                <Row className="mx-1">
                  <Label className="mt-2 text-muted">From</Label>
                  <Input
                    style={{
                      width: "140px",
                      marginLeft: "5px",
                      height: "38px",
                    }}
                    onChange={handleStartDateChange}
                    value={searchData.startDate}
                    type="date"
                    id="startDate"
                    name="startDate"
                    max={new Date().toISOString().split("T")[0]} // Set max date to today's date
                  />
                  <Label className="mx-2 mt-2 text-muted">To</Label>
                  <Input
                    style={{ width: "140px", height: "38px" }}
                    onChange={handleEndDateChange}
                    value={searchData.endDate}
                    type="date"
                    id="endDate"
                    name="endDate"
                    min={searchData.startDate}
                    max={new Date().toISOString().split("T")[0]} // Set max date to today's date
                  />
                </Row>
              </Col>
              <Col md="4" sm="12">
                <Button
                  type="button"
                  outline
                  color="info"
                  className="ml-md--8 ml-0"
                  style={{
                    height: "40px",
                    paddingTop: "8px",
                  }}
                  onClick={handleSubmit}
                >
                  Search
                </Button>
                <Button
                  type="button"
                  outline
                  color="info"
                  //   className="ml-md--8"
                  style={{
                    height: "40px",
                    paddingTop: "8px",
                  }}
                  onClick={handleClear}
                >
                  Clear
                </Button>
              </Col>
            </Row> */}
          </CardBody>
          <CardBody>
            <ToolkitProvider data={tempData} keyField="ID" columns={columns}>
              {(props) => (
                <div className="py-4 overflow-auto table-responsive">
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    bordered={false}
                  />
                </div>
              )}
            </ToolkitProvider>
          </CardBody>
        </Card>
      </Container>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered={true}
        size="lg"
        style={style.modal}
      >
        <ModalHeader className="mb--2">Remarks</ModalHeader>
        <div style={style.modal}>
          <Divider variant="middle" className="" />
          <ModalBody className="d-flex flex-column py-2">
            <div>
              <Label className="m-0 p-0 font-weight-bold">
                Date 30 Oct 2024
              </Label>
            </div>
            <div
              style={{ height: "auto", maxHeight: "10rem", overflow: "auto" }}
            >
              <Label>
                This is a static remark This is a static remark This is a static
                remark
              </Label>
            </div>
          </ModalBody>
          <Divider variant="middle" className="" />
          <ModalBody className="d-flex flex-column py-2">
            <div>
              <Label className="m-0 p-0 font-weight-bold">
                Date 29 Oct 2024
              </Label>
            </div>
            <div
              style={{ height: "auto", maxHeight: "10rem", overflow: "auto" }}
            >
              <Label>
                This is a static remark This is a static remark This is a static
                remark
              </Label>
            </div>
          </ModalBody>
          <Divider variant="middle" className="" />
          <ModalBody className="d-flex flex-column py-2">
            <div>
              <Label className="m-0 p-0 font-weight-bold">
                Date 28 Oct 2024
              </Label>
            </div>
            <div
              style={{ height: "auto", maxHeight: "10rem", overflow: "auto" }}
            >
              <Label>
                This is a static remark This is a static remark This is a static
                remark
              </Label>
            </div>
          </ModalBody>
          <Divider variant="middle" className="" />
          <ModalBody className="d-flex flex-column py-2">
            <div>
              <Label className="m-0 p-0 font-weight-bold">
                Date 27 Oct 2024
              </Label>
            </div>
            <div
              style={{ height: "auto", maxHeight: "10rem", overflow: "auto" }}
            >
              <Label>
                This is a static remark This is a static remark This is a static
                remark
              </Label>
            </div>
          </ModalBody>
          <Divider variant="middle" className="" />
          <ModalBody className="d-flex flex-column py-2">
            <div>
              <Label className="m-0 p-0 font-weight-bold">
                Date 26 Oct 2024
              </Label>
            </div>
            <div
              style={{ height: "auto", maxHeight: "10rem", overflow: "auto" }}
            >
              <Label>
                This is a static remark This is a static remark This is a static
                remark
              </Label>
            </div>
          </ModalBody>
          <Divider variant="middle" className="" />
          <ModalBody className="d-flex flex-column py-2">
            <div>
              <Label className="m-0 p-0 font-weight-bold">
                Date 25 Oct 2024
              </Label>
            </div>
            <div
              style={{ height: "auto", maxHeight: "10rem", overflow: "auto" }}
            >
              <Label>
                This is a static remark This is a static remark This is a static
                remark
              </Label>
            </div>
          </ModalBody>
          <Divider variant="middle" className="" />
          <ModalBody className="d-flex flex-column py-2">
            <div>
              <Label className="m-0 p-0 font-weight-bold">
                Date 24 Oct 2024
              </Label>
            </div>
            <div
              style={{ height: "auto", maxHeight: "10rem", overflow: "auto" }}
            >
              <Label>
                This is a static remark This is a static remark This is a static
                remark
              </Label>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
}

export default AbsentUsers;

const style = {
  loaderColor: { color: cyanBlue },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
    width: "100%",
  },
  loaderText: {
    fontWeight: "normal",
  },
  actionColumn: {
    display: "flex",
    flexDirection: "row",
    columnGap: "0.5rem",
    alignItems: "center",
  },
  cursor: { cursor: "pointer", fontSize: "1rem" },
  modal: {
    height: "30rem",
    overflow: "auto",
  },
};
