/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

function TimelineHeader({
  name,
  showCreateCampaignBtn,
  showBack,
  onClickBack,
  isDetails = false,
}) {
  return (
    <>
      <div
        className={`header header-dark bg-info ${
          isDetails ? "pb-3" : "pb-6"
        } content__title content__title--calendar`}
      >
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4 mt--4">
              <Col lg="6" xs="7">
                <Row className="d-flex">
                  {showBack && (
                    <Button
                      className="btn-neutral"
                      color="default"
                      size="sm"
                      onClick={onClickBack}
                    >
                      <i className="fa fa-arrow-left" /> Back
                    </Button>
                  )}
                  <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                    {name}
                  </h6>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
