/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import CardsHeader from "components/Headers/CardsHeader";
import TenantTab from "pages/Tenants/Components/TenantTab";
import React from "react";

function Dashboard() {
  return (
    <>
      <CardsHeader name="Company Name" parentName="Dashboards" />
      <TenantTab activeTab="Dashboard" />
    </>
  );
}

export default Dashboard;
