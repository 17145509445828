import { GetCountrys } from "constants/ReducerConstants/Constants";

const InitialState = {
  countryList: {},
};

const CountryReducer = (state = InitialState, action) => {
  switch (action.type) {
    case GetCountrys: {
      return { ...state, countryList: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default CountryReducer;
