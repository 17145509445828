const { RedirectLinks } = require("constants/ReducerConstants/Constants");

const initialState = {
  redirectLinks: {},
};

const RedirectLinksReducer = (state = initialState, action) => {
  switch (action.type) {
    case RedirectLinks:
      return { ...state, redirectLinks: action.payload };
    default:
      return state;
  }
};

export default RedirectLinksReducer;
